import * as React from "react";

type Props = {
  /** The size of the icon, 24px is default to match standard icons */
  size?: number;
  /** The color of the icon, defaults to the current text color */
  fill?: string;
};

export default function Icon({ size = 24, fill = "currentColor" }: Props) {
  return (
    <svg
      fill={fill}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      version="1.1"
    >
      <path d="M7.36156 14.1107C7.36156 15.0358 6.60586 15.7915 5.68078 15.7915C4.7557 15.7915 4 15.0358 4 14.1107C4 13.1857 4.7557 12.43 5.68078 12.43H7.36156V14.1107ZM8.20847 14.1107C8.20847 13.1857 8.96417 12.43 9.88925 12.43C10.8143 12.43 11.57 13.1857 11.57 14.1107V18.3192C11.57 19.2443 10.8143 20 9.88925 20C8.96417 20 8.20847 19.2443 8.20847 18.3192V14.1107ZM9.88925 7.36156C8.96417 7.36156 8.20847 6.60586 8.20847 5.68078C8.20847 4.7557 8.96417 4 9.88925 4C10.8143 4 11.57 4.7557 11.57 5.68078V7.36156H9.88925ZM9.88925 8.20847C10.8143 8.20847 11.57 8.96417 11.57 9.88925C11.57 10.8143 10.8143 11.57 9.88925 11.57H5.68078C4.7557 11.57 4 10.8143 4 9.88925C4 8.96417 4.7557 8.20847 5.68078 8.20847H9.88925ZM16.6384 9.88925C16.6384 8.96417 17.3941 8.20847 18.3192 8.20847C19.2443 8.20847 20 8.96417 20 9.88925C20 10.8143 19.2443 11.57 18.3192 11.57H16.6384V9.88925ZM15.7915 9.88925C15.7915 10.8143 15.0358 11.57 14.1107 11.57C13.1857 11.57 12.43 10.8143 12.43 9.88925V5.68078C12.43 4.7557 13.1857 4 14.1107 4C15.0358 4 15.7915 4.7557 15.7915 5.68078V9.88925ZM14.1107 16.6384C15.0358 16.6384 15.7915 17.3941 15.7915 18.3192C15.7915 19.2443 15.0358 20 14.1107 20C13.1857 20 12.43 19.2443 12.43 18.3192V16.6384H14.1107ZM14.1107 15.7915C13.1857 15.7915 12.43 15.0358 12.43 14.1107C12.43 13.1857 13.1857 12.43 14.1107 12.43H18.3192C19.2443 12.43 20 13.1857 20 14.1107C20 15.0358 19.2443 15.7915 18.3192 15.7915H14.1107Z" />
    </svg>
  );
}
